/* ------------ Home Slider ------------- */
$(document).ready(function(){
    /* fix scrollspy doesn't scroll on document ready -> display slider only if hash is absent on root url or on click on .display-slider link  */
    if (window.location.hash) {
        $('#agc #topNav ul li a, #go-top a').addClass('display-slider');
        $('a.display-slider').on('click', function(){
            $( '#agc #slider' ).sliderPro({
                width: '100%',
                height: 768,
                fade: true,
                waitForLayers: true,
                buttons: true,
                autoplay: true,
                autoScaleLayers: false,
                slideAnimationDuration: 1500,
                breakpoints: {
                    600: {
                        height: 480
                    }
                }
            });
        });
    }
    else {
        $( '#agc #slider' ).sliderPro({
            width: '100%',
            height: 768,
            fade: true,
            waitForLayers: true,
            buttons: true,
            autoplay: true,
            autoScaleLayers: false,
            slideAnimationDuration: 1500,
            breakpoints: {
                600: {
                    height: 480
                }
            }
        });
    }
});

/* -------- Appears Menu ------ */
/* replace background-color on scroll > 30 */
$(window).on('ready , scroll', function() {
    if ($(window).scrollTop() > 30) {
        $('#agc .main-menu').addClass('minified');
    } else {
        $('#agc .main-menu').removeClass('minified');
    }
});

/* ---------- Hide Menu -------- */
$(window).resize(function(){
    var width = $(window).width();
    if(width >= 0 && width <= 991){
        $('#agc #collapsingNavbar').removeClass('show').addClass('collapse');
        $('#agc .navbar-toggler').removeClass('collapse').addClass('show')
        $('#agc .main-menu .navbar-nav li').on('click', function(){
            $('#collapsingNavbar').removeClass('show').addClass('collapse');
        });
    }
    else{
        $('#agc #collapsingNavbar').removeClass('collapse').addClass('show');
        $('#agc .navbar-toggler').removeClass('show').addClass('collapse')
        $('#agc .main-menu .navbar-nav li').on('click', function(){
            $('#agc #collapsingNavbar').removeClass('collapse').addClass('show');
        });
    }
})
    .resize();//trigger the resize event on page load.

$( document ).ready(function() {
    var width = $(window).width();
    if(width >= 0 && width <= 991){
        $('#agc #collapsingNavbar').removeClass('show').addClass('collapse');
        $('#agc #collapsingNavbar2').removeClass('show').addClass('collapse');
    }
});

/* --------- One Page Navigation -------- */
$( document ).ready(function() {
    /* add and remove class active on navbar links */
    var element = document.getElementsByClassName("navbar-nav")[0];
    /* verify if element is not null */
    if(element) {
        element.addEventListener("click", myFunction);

        function myFunction(e) {
            var elems = document.querySelector(".active");
            if (elems != null) {
                elems.classList.remove("active");
            }
            e.target.className = "active";
        }
    }

    /* display home element */
    if (window.location.href.indexOf("#") > -1) {

    }
    else {
        /* run the AJAX function */
        if ($(window).width() > 768) {
            $("#agc #a-propos-de-nous, #agc #services, #agc #tarifs, #agc #partenaires, #agc #contact, #agc .footer-containertent").addClass("fadeSection");
            // Every time the window is scrolled ...
            $(window).scroll(function () {
                //Check the location of each desired element
                $('#agc #a-propos-de-nous.fadeSection, #agc #services.fadeSection, #agc #tarifs.fadeSection, #agc #partenaires.fadeSection, #agc #contact.fadeSection, #agc .footer-containertent.fadeSection').each(function (i) {
                    var bottom_of_object = $(this).position().top + ( $(this).outerHeight() / 6 );
                    var bottom_of_window = $(window).scrollTop() + $(window).height();
                    //If the object is completely visible in the window, fade it in
                    if (bottom_of_window > bottom_of_object) {
                        $(this).animate({'opacity': '1'}, 500);
                    }
                });
            });
        };
    };
});

/* display go top */
/* scrollTo */
$('.scrollTo').click( function() {
    var page = $(this).attr('href');
    var speed = 750;
    $('html, body').animate( { scrollTop: $(page).offset().top }, speed );
    return false;
});

$(document).scroll(function () {
    var y = $(this).scrollTop();
    if (y > 500) {
        $('#go-top').fadeIn();
    } else {
        $('#go-top').fadeOut();
    }
});

/* cookies consent */
$(document).ready(function () {
    //let tarteaucitronSource = $('#tarteaucitron-script-src').val();
    //$("body").append($("<script></script>").attr("src", tarteaucitronSource));

    //if (typeof tarteaucitron === 'object') {
    tarteaucitron.init({
        "privacyUrl": "", /* Privacy policy url */
        "bodyPosition": "bottom", /* or top to bring it as first element for accessibility */

        "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
        "cookieName": "tarteaucitron", /* Cookie name */

        "orientation": "bottom", /* Banner position (top - bottom - middle - popup) */

        "groupServices": false, /* Group services by category */
        "showDetailsOnClick": true, /* Click to expand the description */
        "serviceDefaultState": "wait", /* Default state (true - wait - false) */

        "showAlertSmall": false, /* Show the small banner on bottom right */
        "cookieslist": true, /* Show the cookie list */

        "showIcon": false, /* Show cookie icon to manage cookies */
        // "iconSrc": "", /* Optionnal: URL or base64 encoded image */
        "iconPosition": "BottomLeft", /* Position of the icon between BottomRight, BottomLeft, TopRight and TopLeft */

        "adblocker": false, /* Show a Warning if an adblocker is detected */

        "DenyAllCta" : true, /* Show the deny all button */
        "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
        "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */

        "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

        "removeCredit": true, /* Remove credit link */
        "moreInfoLink": false, /* Show more info link */
        "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */
        "useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */

        //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for subdomain website */

        "readmoreLink": "/cookiespolicy", /* Change the default readmore link */

        "mandatory": false, /* Show a message about mandatory cookies */
        "mandatoryCta": true, /* Show the disabled accept button when mandatory on */

        // "customCloserId": "", /* Optional a11y: Custom element ID used to open the panel */

        "googleConsentMode": true, /* Enable Google Consent Mode v2 for Google ads and GA4 */

        "partnersList": false /* Details the number of partners on the popup and middle banner */
    });

    tarteaucitron.user.gajsUa = 'UA-172746450-1';
    tarteaucitron.user.gajsMore = function () { /* add here your optionnal _ga.push() */ };
    (tarteaucitron.job = tarteaucitron.job || []).push('gajs');

    //}
});